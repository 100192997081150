import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../../vendor.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],

  host: {
    '(window:click)': 'onClick()'

  }
})

export class HeaderComponent implements OnInit, OnDestroy {
  public isUsernameDropdown = false;
  public isSupplierDropdownShown = false;
  public isHomeDropdownShown = false;
  public isHomeDropdownPortal = false;
  activeHome: boolean = true;
  activePortalPrinciple: boolean = false;
  activeOnlineRepo: boolean = false;
  portalTranslate: boolean = false;
  public portalImage = false;
  loggedInlanguage: any;
  role: any;
  roleCH: any;
  public logoutURL!: string;
  loggedInCode!: string;
  isnormalView: boolean = true;
  isadminView: boolean = false;
  activeSupport: boolean = false;
  public hideDropDown = true;
  public IDMSPersona = '';
  public firstName: any;
  public userName: any;
  public code = '';
  adminVerticalline: boolean = false;
  normalVerticalline = true;
  public companyDynamicDataview: any;
  public companyDynamicData: any;
  public companyDynamicDatalastUpdate: any;
  public companyDynamicDataviewMessage: any;
  public companyDynamicDataFlag: boolean = false;
  companyName: any;
  public hideInPortalPrinciple: boolean = false;
  public activeSupplierAtSE = false;
  editProfile!: string;
  feedbackwindowClose: boolean = false;
  logoutlinkClicked: boolean = false;

  ngOnInit(): void {
    this._service.IdmsInfo.subscribe(data => {
      if (data) {
        this.loggedInlanguage = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
        console.log("Header countryCode", this.loggedInlanguage.countryCode);
        if (this.loggedInlanguage.countryCode === "CN") {
          this.logoutURL = environment.logoutChinaURL;
          this.editProfile = environment.editProfileCH;
          console.log("Profile China URL", this.editProfile);
          console.log("Logout China URL", this.logoutURL);
        }
        else {
          this.logoutURL = environment.logoutGlobalURL;
          this.editProfile = environment.editProfileEN;
          console.log("Profile Global URL", this.editProfile);
          console.log("Logout Global URL", this.logoutURL);
        }
        this.IDMSPersona = this.loggedInlanguage.persona;
        this.firstName = this.loggedInlanguage.firstName;
        this.userName = this.loggedInlanguage.firstName + ' ' + this.loggedInlanguage.lastName;
        this.code = sessionStorage.getItem('Idmscode') || '';
        this.role = this.loggedInlanguage.portalUserType;
        this.roleCH = this.loggedInlanguage.portalUserTypeCH;
        if (this.role !== "Admin User" || this.roleCH !== "管理员") {
          this.hideDropDown = true;
        }
        this.companyName = this.loggedInlanguage.companyName;
        if (!this.companyName) {
          this.companyDynamicData = JSON.parse(sessionStorage.getItem('dynamicData') || '{}');
          this.companyName = this.companyDynamicData.supplierName;
        }
        this._service.getHomePageContent();
        this._service.getpersonaName();
        this._service.getpersonaSurvey();
        this._service.getContract();
        this._service.getMyaccount();
        this._service.getGeneral();
        this._service.getDynamicData();
        this._service.getActionPlan();
        this._service.coupaDropdownList();
        this._service.getMyaccount();
        this._service.supplyOnDropdown();
      }
    })
    // this._service.isUserLoggedIn();
    this._service.DynamicAPI.subscribe((data) => {
      if (data) {
        this.companyDynamicData = JSON.parse(sessionStorage.getItem('dynamicData') || '{}');
        this.companyDynamicDataview = this.companyDynamicData.completion;
        this.companyDynamicDatalastUpdate = this.companyDynamicData.lastUpdateDate;
        this.companyDynamicDataFlag = this.companyDynamicData.showNotification;

        this.companyDynamicDataviewMessage = this.companyDynamicData.message;

        this.loggedInlanguage = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
        this.companyName = this.loggedInlanguage.companyName;
        if (!this.companyName) {
          this.companyDynamicData = JSON.parse(sessionStorage.getItem('dynamicData') || '{}');
          this.companyName = this.companyDynamicData.supplierName;
        }
      }
    });
    // new

    this._service.procureToPayDropdown.subscribe(data => {
      if (data.length) {
        let procureToPayDropdownList: any = data;
        this._service.getCoupaTabaleData(procureToPayDropdownList[0].id)
      }
    })
    this._service.getImage();
    this._service.getfeedbackDownloadLink();
    this._service.getportalPrincipal();
    this._service.getContact();
    this._service.ratingSummary();
    this._service.getcontactTable();
  }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  constructor(public translate: TranslateService, private _service: VendorService) { }

  scrollHandler(event: any) {
    this.onClick();
  }

  public onClick(): void {
    this.isUsernameDropdown = false;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
    this.isHomeDropdownPortal = false;
  }

  public onPortalDropdownClickleave($event: any): void {
    $event.stopPropagation();
    this.isUsernameDropdown = false;
    this.isHomeDropdownPortal = false;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
  }

  public onPortalDropdownClick($event: any): void {
    $event.stopPropagation();
    this.isUsernameDropdown = false;
    this.isHomeDropdownPortal = true;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
  }

  public sspsrmInego(): void {
    this.activePortalPrinciple = false;
    this.activeHome = true;
    this.hideInPortalPrinciple = false;
    this.activeSupplierAtSE = false;
    this.activeSupport = false;
    this.activeOnlineRepo = false;
  }

  public navigateTosspsrm(): void {
    this.activePortalPrinciple = true;
    this.activeHome = false;
    this.activeSupplierAtSE = false;
    this.activeSupport = false;
    this.activeOnlineRepo = false;
  }

  public navigateToOnlineRepo(): void {
    this.activeOnlineRepo = true;
    this.activePortalPrinciple = false;
    this.activeHome = false;
    this.activeSupplierAtSE = false;
    this.activeSupport = false;
  }

  public onSupplierAtSEDropdownClick($event: any): void {
    $event.stopPropagation();
    this.isUsernameDropdown = false;
    this.isSupplierDropdownShown = true;
    this.isHomeDropdownShown = false;
    this.isHomeDropdownPortal = false;
  }

  public switchLanguage(): void {
    const language: any = document.getElementById('switch');
    if (language.checked) {
      this.translate.use('cn');
      console.log(this.translate.store.currentLang);
      this.portalTranslate = true;
    }
    else {
      this.translate.use('en');
      this.portalTranslate = false;
    }

  }


  public onUsernameClick($event: any): void {
    $event.stopPropagation();
    this.isUsernameDropdown = !this.isUsernameDropdown;
    this.isHomeDropdownPortal = false;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
  }
  public onUsernameClickleave($event: any): void {
    $event.stopPropagation();
    this.isUsernameDropdown = false;
    this.isHomeDropdownPortal = false;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
  }

  public normalView(): void {
    this.isadminView = false;
    this.isnormalView = true;
    this.adminVerticalline = false;
    this.normalVerticalline = true;
    this.hideInPortalPrinciple = false;

  }

  public adminView(): void {
    this.isadminView = true;
    this.isnormalView = false;
    this.adminVerticalline = true;
    this.normalVerticalline = false;

  }

  public navigateTosspinego(): void {
    this.activePortalPrinciple = false;
    this.activeHome = false;
    this.activeSupport = true;
    this.activeSupplierAtSE = false;
    this.activeOnlineRepo = false;

  }

  public logout() {
    this.feedbackwindowClose = true;
    this.logoutlinkClicked = true;
    setTimeout(() => {
      sessionStorage.removeItem('UserLoggedInDetails');
      window.location.href = this.logoutURL;
    }, 60000);

  }


  public starClicked() {
    this.feedbackwindowClose = true
    this.logoutlinkClicked = false;
    // this.feedbackwindowClose1 = true;
    // this.starRatingScreen = true;
    // this.nextButton = true;
    // this.showstarsuccessmessage = false;
  }

  dialogClosed(event: any) {
    this.feedbackwindowClose = event
  }

}