<div class="desktopView postion-relative" id="newposition" style="background-color:#fafafa ;">
    <app-welcome-text [welcomeTranslate]="portalTranslate"></app-welcome-text>

    <div fxFlex fxLayout="column" (scroll)="scrollHandler($event)" id="newposition">
        <!-- Header -->
        <div
            style="z-index: 3;background-color: #F3F2F2; box-shadow: 0px 0px 2px rgba(51, 51, 51, 0.05), 0px 2px 10px rgba(51, 51, 51, 0.15);">

            <se-tabbar color="primary" class="desktopView">
                <div style=" background-color: #fafafa; box-shadow: 0px 0px 2px rgba(51, 51, 51, 0.05), 0px 2px 10px rgba(51, 51, 51, 0.15);"
                    [style.height]="portalTranslate?'80px':'80px'">
                    <div class="surround21" style="margin-top: 0px !important">
                        <nav class="d-flex justify-content-start border-0 ">

                            <a id="help" href="https://www.se.com" target="_blank"
                                style="margin: 0; width: 23.2%; padding-left: 1%;">
                                <img src="../assets/images/SE_logotype_lifeison_white.svg" width="100%"
                                    style="background-color: #3dcd58;height:inherit" />
                            </a>
                            <div>
                                <p class="englishHeader" (mouseleave)="onPortalDropdownClickleave($event)">
                                    {{ "home.headerInfo" | translate }}
                                </p>
                            </div>
                        </nav>
                    </div>
                </div>
            </se-tabbar>


            <div class="d-flex justify-content-center" style="background-color: #fafafa;">
                <se-tabbar>
                    <div class="d-flex justify-content-between" style="background-color: #fafafa; width: 1190px;">
                        <div class="main-menu">
                            <se-tabbar *ngIf="isnormalView">
                                <nav *ngIf="isnormalView" class="normal-menu border-bottom-0">
                                    <a id="supplier" [ngClass]="{'activeMenu':activeHome}">
                                        <span (mouseover)="onPortalDropdownClick($event)" (click)="sspsrmInego();"
                                            class="headerNav">{{
                                            "home.c" | translate }}</span>
                                    </a>

                                    <a class="headerNav" (click)="navigateToOnlineRepo()" id="onlineRepo"
                                        [ngClass]="{'activeMenu':activeOnlineRepo}">
                                        <span> {{ 'home.knowledgeHub' | translate }}</span>
                                    </a>

                                    <!-- <a class="headerNav" (click)="navigateTosspsrm()" id="portalPrinciple"
                                        [ngClass]="{'activeMenu':activePortalPrinciple}">
                                        <span> {{ 'home.PortalPrinciple' | translate }}</span>
                                        <span> {{ 'home.Cockpit360' | translate }}</span>
                                    </a> -->

                                    <a [ngClass]="{'activeMenu':activeSupplierAtSE}" class="headerNav"
                                        href="https://www.se.com/ww/en/about-us/suppliers/#Useful%20Information"
                                        target="_blank">
                                        <span (click)="onSupplierAtSEDropdownClick($event)"> {{
                                            'home.d' | translate
                                            }}</span>
                                    </a>
                                    <a [ngClass]="{'activeMenu':activeSupport}" id="supplier" class="headerNav">
                                        <span (click)="navigateTosspinego()"> {{ 'home.f' | translate }}</span>
                                    </a>
                                </nav>
                            </se-tabbar>
                        </div>
                        <div class="profile-details">
                            <div class="d-flex align-items-end gap-3">
                                <div style="margin-bottom: 18px">
                                    <div style="color: #333333" (mouseleave)="onPortalDropdownClickleave($event)">
                                        <span class="translate-en">En &nbsp;
                                            <input type="checkbox" (click)="switchLanguage();" id="switch"
                                                class="checkbox" />
                                            <label for="switch" class="toggle" style="height:20px;cursor: pointer;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            </label>
                                            &nbsp; 中文
                                        </span>
                                    </div>
                                </div>

                                <div class="banner userName">
                                    <span style="margin-left:1%; position: relative;">
                                        <div class="d-flex user-details" (click)="onUsernameClick($event)">
                                            <a class="desktopNotification"
                                                style="text-decoration: none; color:#000001;">
                                                <span style="font-size: 16px;">{{firstName}}</span>
                                            </a>
                                            <div class="d-flex flex-shrink-1">
                                                <se-icon size="small" class="downArrow">
                                                    arrow3_down</se-icon>
                                            </div>
                                        </div>


                                        <span class="custom-dashboard" style="position:fixed ; top:150px"
                                            *ngIf="isUsernameDropdown">
                                            <div *ngIf="userName && !hideInPortalPrinciple">
                                                <div class="welcomeDashBoard">
                                                    <div class="profile-name">
                                                        <span class="welcome-text"> {{ "home.welcome"
                                                            | translate }}</span>
                                                        <span class="user-name mb-1"> {{userName}}</span>
                                                        <span class="view-profile">
                                                            <a href="{{editProfile}}" target="_blank"
                                                                style="font-size: 14px;color:#0087CD; display: block;">{{
                                                                "home.viewProfile" | translate }}
                                                            </a>
                                                        </span>
                                                    </div>

                                                    <div class="company-details mt-3" *ngIf="companyName">
                                                        <span class="company-title">{{ "home.comp" |
                                                            translate }}</span>
                                                        <span class="company-name">{{companyName}}</span>
                                                    </div>

                                                    <div class="role-details mt-3" *ngIf="role || roleCH">
                                                        <span class="role-title"> {{ "home.role" |
                                                            translate }}</span>
                                                        <span class="role-name" *ngIf="!portalTranslate">{{role}}</span>
                                                        <span class="role-name"
                                                            *ngIf="portalTranslate">{{roleCH}}</span>
                                                    </div>

                                                    <div class="admin-normal-view mt-3">
                                                        <a class="dropdownitems" id="portalPrinciple"
                                                            style="display: block;" (click)="normalView();"
                                                            *ngIf="IDMSPersona ==='Employee' && !isnormalView">
                                                            <span
                                                                [ngClass]="{'verticalLine':normalVerticalline}"></span>
                                                            <span style="font-size: 17px;color:#0087CD">
                                                                {{ "home.normal" | translate
                                                                }}</span>
                                                        </a>
                                                        <a class="dropdownitems" id="portalPrinciple"
                                                            (click)="adminView()"
                                                            *ngIf="IDMSPersona ==='Employee' && isnormalView && hideDropDown"
                                                            style="display: block;">
                                                            <span [ngClass]="{'verticalLine':adminVerticalline}"></span>
                                                            <span style="font-size: 17px;color:#0087CD">
                                                                {{ "home.admin" | translate }}</span>
                                                        </a>
                                                    </div>

                                                    <div class="logout-button mt-3">
                                                        <button type="button" class="btn btn-primary" (click)="logout()"
                                                            target="_self">
                                                            {{ 'home.Logout' | translate}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </se-tabbar>
            </div>

        </div>
    </div>
    <!-- Header end -->
</div>


<!-- mian view -->
<div class="desktopView tileSection">
    <div *ngIf="isnormalView">
        <app-normal-view [portalTranslate]="portalTranslate" [isActivePortalPrinciple]="activePortalPrinciple"
            [isActiveOnlineRepo]="activeOnlineRepo" [isHome]="activeHome"
            [isactiveSupport]="activeSupport"></app-normal-view>
    </div>

    <div *ngIf="isadminView && IDMSPersona=='Employee'">
        <app-admin-view [portalTranslate]="portalTranslate"></app-admin-view>
    </div>
</div>
<!-- main view ends -->
<div *ngIf="feedbackwindowClose">
    <app-feedback-rating [feedbackwindowValue]="feedbackwindowClose" [logoutlinkClicked]="logoutlinkClicked"
        (closedDialog)="dialogClosed($event)"></app-feedback-rating>
</div>

<div *ngIf="isnormalView" class="desktopView" (click)="starClicked()" style="position: fixed;
    bottom: 30%;
    right: 1%;
    width: 90px;
    background-color: #626469;
    height: 90px;
    cursor: pointer;
    z-index: 9;">
    <se-icon size="large" style="font-size: 56px;
      padding-top: 18px;
      padding-left: 18px;width: 100%;
      color: white;
    ">thumb_up</se-icon>
</div>

<app-mobile-section></app-mobile-section>