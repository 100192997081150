export const environment = {
    production: false,
    currentConfig: 'uat',
    region: 'us-east-2',
    endPoint: 'https://7sb6mpen4fhsllrw6ukgpjbt2i.appsync-api.us-east-2.amazonaws.com/graphql',
    apiKey: 'da2-g62vif3a4vf23nvlmw25fzwfla',
    IDMSLoginURL: 'https://idp-int.se.com/authorize?client_id=ZScPhG3Itzqb5xEeogehgXGdS4IBZaIE&scope=openid+profile+email&redirect_uri=https://test-vr.d2knpdomzzuhny.amplifyapp.com&response_type=code',
    IDMSChinaLoginURL: 'https://idp-stg.schneider-electric.cn/identity/login?app=supplierPortalVendor',
    // IDMSLoginURL: 'https://secureidentity-uat.schneider-electric.com/identity/idplogin?app=SupplierPortalVendor&response_type=code&client_id=3MVG9MzBf_P2Sb150wiNlSTTcvykHemyEP08fAwETj2svrFfADn1DTEHQlvtDnqGIy3vYxry7SIfb3lHkVFKF&redirect_uri=https://test-ssp.se.com/',
    companyInformation: 'https://schneider-electric-pp.synertrade.com/sdl/supplier/myaccount.do',
    // logoutURL: 'https://secureidentity-uat.schneider-electric.com/identity/IDMS_IDPSloInit?RelayState=https://test-ssp.se.com/&app=SupplierPortalVendor'
    logoutGlobalURL: 'https://idp-int.se.com/oidc/logout?post_logout_redirect_uri=https://staging.d2zbmx6mli2qu1.amplifyapp.com/&client_id=ZScPhG3Itzqb5xEeogehgXGdS4IBZaIE',
    logoutChinaURL: 'https://idp-stg.schneider-electric.cn/esc-sso/logout?app=supplierPortalVendor',
    Contracts: 'https://schneider-electric-pp-idms.synertrade.com/sdl/contract/supplier/contracts.do',
    ActionPlan: 'https://schneider-electric-pp-idms.synertrade.com/sdl/srm/supplier/measures.do',
    Auctions: 'https://schneider-electric-pp-idms.synertrade.com/sdl/auction/supplier/auctions.do',
    MyAccountDocument: 'https://schneider-electric-pp.synertrade.com/sdl/supplier/myaccount.do',
    GeneralDocuments: 'https://schneider-electric-pp-idms.synertrade.com/sdl/dms/supplier/documents.do',
    SupplierQualification: 'https://schneider-electric-pp-idms.synertrade.com/sdl/supplier/additionainformation.do',
    CompanyInformation: 'https://schneider-electric-pp-idms.synertrade.com/sdl/supplier/myaccount.do',
    ESurvey: 'https://schneider-electric-pp-idms.synertrade.com/sdl/rfx/supplier/projects.do',
    performanceDashboard: 'https://schneider-electric-pp-idms.synertrade.com/sdl/supplier/reporting.do',
    editProfileEN: 'https://idp-int.se.com/identity/profile?app=ZScPhG3Itzqb5xEeogehgXGdS4IBZaIE',
    editProfileCH: 'https://idp-stg.schneider-electric.cn/identity/userprofile?app=SupplierPortalVendor',
    zeroCarbonUrl: 'https://hub-pre.zeigo.com/idms-sso-login',
    SPCNUrl: 'https://schneider-electric-pp-idms.synertrade.com/sdl/srm/supplier/measures.do',
    coupaMyAccount: 'https://schneider-electric.coupahost.com/user/home',
    supplyOnMyAccount: 'https://webedi.application.prd.supplyon.com/dashboard/#/dashboards/1',
    coInnovationAccount: 'https://se.yoomap.pub',
    i2pUrl: 'https://se--uatbfo22.sandbox.my.site.com/ExtranetI2PLightning',
};