import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { SeWebModule } from '@se/web-ui-angular';
// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { GridModule } from '@progress/kendo-angular-grid';
import { environment } from '../environments/environment';
import { Apollo } from 'apollo-angular';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from "@progress/kendo-angular-charts";
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { EditorModule } from "@progress/kendo-angular-editor";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { PopupModule } from "@progress/kendo-angular-popup";
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { DialogModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from '@progress/kendo-angular-inputs';
import { InterceptorService } from '../app/common/Loader/interceptor.service';
import { ActionPlanComponent } from './home/ssp-srm/action-plan/action-plan.component';
import { AdminHometableComponent } from './admin/admin-hometable/admin-hometable.component';
import { AdminViewComponent } from './admin/admin-view/admin-view.component';
import { AuctionsComponent } from './home/ssp-srm/auctions/auctions.component';
import { CompanyInfoComponent } from './home/ssp-srm/company-info/company-info.component';
import { ContactFormComponent } from './support/contact-form/contact-form.component';
import { ContactSupportTableComponent } from './support/contact-support-table/contact-support-table.component';
import { ContractComponent } from './home/ssp-srm/contract/contract.component';
import { CoupaOrderComponent } from './home/coupa-order/coupa-order.component';
import { ESourceComponent } from './home/ssp-srm/e-source/e-source.component';
import { ESurveyComponent } from './home/ssp-srm/e-survey/e-survey.component';
import { FeedbackRatingComponent } from './common/feedback-rating/feedback-rating.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { MobileSectionComponent } from './common/mobile-section/mobile-section.component';
import { NormalViewComponent } from './home/normal-view/normal-view.component';
import { PortalPrincipalComponent } from './portal-principal/portal-principal.component';
import { RegionWisePieChartComponent } from './admin/region-wise-pie-chart/region-wise-pie-chart.component';
import { SspSrmComponent } from './home/ssp-srm/ssp-srm.component';
import { SupplyonDeliveryComponent } from './home/supplyon/supplyon-delivery/supplyon-delivery.component';
import { SupplyonOrderComponent } from './home/supplyon/supplyon-order/supplyon-order.component';
import { TrafficMonitoringComponent } from './admin/traffic-monitoring/traffic-monitoring.component';
import { TrafficMonitoringMonthlyComponent } from './admin/traffic-monitoring-monthly/traffic-monitoring-monthly.component';
import { WelcomeTextComponent } from './common/welcome-text/welcome-text.component';
import { VendorService } from './vendor.service';
import { EsourcingRepoComponent } from './online-repository/ssp-srm-repo/user-guides-repo/esourcing-repo/esourcing-repo.component';
import { AuctionsRepoComponent } from './online-repository/ssp-srm-repo/user-guides-repo/auctions-repo/auctions-repo.component';
import { ContractsRepoComponent } from './online-repository/ssp-srm-repo/user-guides-repo/contracts-repo/contracts-repo.component';
import { ActionplanRepoComponent } from './online-repository/ssp-srm-repo/user-guides-repo/actionplan-repo/actionplan-repo.component';
import { SpcnRepoComponent } from './online-repository/ssp-srm-repo/user-guides-repo/spcn-repo/spcn-repo.component';
import { GeneralRepoComponent } from './online-repository/ssp-srm-repo/user-guides-repo/general-repo/general-repo.component';
import { UserGuidesRepoComponent } from './online-repository/ssp-srm-repo/user-guides-repo/user-guides-repo.component';
import { OnlineRepositoryComponent } from './online-repository/online-repository.component';
import { SupplierChatbotComponent } from './common/supplier-chatbot/supplier-chatbot.component';
import { BusinessReviewComponent } from './online-repository/ssp-srm-repo/user-guides-repo/business-review/business-review.component';
import { I2pRepoComponent } from './online-repository/i2p-repo/i2p-repo.component';
import { CoinnovationRepoComponent } from './online-repository/coinnovation-repo/coinnovation-repo.component';
import { UserGuidesScmComponent } from './online-repository/ssp-scm-repo/user-guides-repo/user-guides-scm.component';
import { DeliveryComponent } from './online-repository/ssp-scm-repo/user-guides-repo/delivery/delivery.component';
import { OrderComponent } from './online-repository/ssp-scm-repo/user-guides-repo/order/order.component';
import { GeneralFunctionsComponent } from './online-repository/ssp-scm-repo/user-guides-repo/general-functions/general-functions.component';
import { ImportantInfoRepoComponent } from './online-repository/important-info-repo/important-info-repo.component';
import { CommunicationsRepoComponent } from './online-repository/important-info-repo/communications-repo/communications-repo.component';
import { NewsletterRepoComponent } from './online-repository/important-info-repo/newsletter-repo/newsletter-repo.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ActionPlanComponent,
    AdminHometableComponent,
    AdminViewComponent,
    AuctionsComponent,
    CompanyInfoComponent,
    ContactFormComponent,
    ContactSupportTableComponent,
    ContractComponent,
    CoupaOrderComponent,
    ESourceComponent,
    ESurveyComponent,
    FeedbackRatingComponent,
    FooterComponent,
    HeaderComponent,
    MobileSectionComponent,
    NormalViewComponent,
    PortalPrincipalComponent,
    RegionWisePieChartComponent,
    SspSrmComponent,
    SupplyonDeliveryComponent,
    SupplyonOrderComponent,
    TrafficMonitoringComponent,
    TrafficMonitoringMonthlyComponent,
    WelcomeTextComponent,
    EsourcingRepoComponent,
    AuctionsRepoComponent,
    ContractsRepoComponent,
    ActionplanRepoComponent,
    SpcnRepoComponent,
    GeneralRepoComponent,
    UserGuidesRepoComponent,
    OnlineRepositoryComponent,
    SupplierChatbotComponent,
    BusinessReviewComponent,
    I2pRepoComponent,
    CoinnovationRepoComponent,
    CommunicationsRepoComponent,
    UserGuidesScmComponent,
    DeliveryComponent,
    OrderComponent,
    GeneralFunctionsComponent,
    ImportantInfoRepoComponent,
    NewsletterRepoComponent,
  ],
  imports: [
    BrowserModule,
    // AppRoutingModule,
    FormsModule,
    PopupModule,
    InputsModule,
    UploadsModule,
    ReactiveFormsModule,
    DropDownListModule,
    BrowserAnimationsModule,
    ChartsModule,
    ProgressBarModule,
    DialogModule,
    ButtonsModule,
    HttpClientModule,
    NgbModule,
    DropDownsModule,
    TooltipModule,
    EditorModule,
    GridModule,
    NgbDropdownModule,
    // AdminModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, VendorService, Apollo],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(apollo: Apollo, private http: HttpClient) {
    // moved environment based constant logic to constants.ts inside assets folder
    const appsyncClient: any = new AWSAppSyncClient({
      disableOffline: true,
      url: environment.endPoint,
      region: environment.region,
      auth: {
        type: AUTH_TYPE.API_KEY,
        apiKey: environment.apiKey,
      },
    });

    apollo.client = appsyncClient;
    // const testURL: string = window.location.search;
    // if (testURL === '') {
    //   this.detectCountryAndRedirect();
    //   window.location.replace(environment.IDMSLoginURL);
    // }
  }

  // detectCountryAndRedirect() {
  //   this.http.get<{ country_code: string }>('https://ipapi.co/json/')
  //     .subscribe((response: any) => {
  //       const countryCode = response.country_code;
  //       const redirectUrl = countryCode === 'CN'
  //         ? environment.IDMSChinaLoginURL
  //         : environment.IDMSLoginURL;

  //       window.location.replace(redirectUrl);
  //     });
  // }
}
